import tokenService from "@/services/TokenService"
import type { NavigationGuardNext } from "vue-router"

interface IProps {
    next: NavigationGuardNext
    store: any
}

const requireAuth =
    (redirectRouteName: string, getUser) =>
    async ({ next, store }: IProps) => {
        if (tokenService.jwt.has()) {
            if (store.user.id === undefined) {
                try {
                    const data = await getUser()
                    store.setUser(data)
                    return next()
                } catch (error) {
                    return redirect(next, store, redirectRouteName)
                }
            }

            return next()
        }

        return redirect(next, store, redirectRouteName)
    }

const redirect = (next, store, redirectRouteName: string) => {
    tokenService.removeTokens()
    store.setUser({})
    return next({ name: redirectRouteName })
}

export default requireAuth
