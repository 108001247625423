import type { IUser } from "@/interfaces"
import client from "@/common/client"

interface ILogin {
    email: string
    password: string
}

interface ICreate {
    email: string
    role: string
}

interface IChangeOwner {
    sourceId: number
    targetId: number
    cvId?: number
}

const userRepository = {
    getList: async (): Promise<IUser[]> => {
        return await client.get("/api/users")
    },
    get: async (): Promise<IUser> => {
        return await client.get("/api/profile")
    },
    login: async (data: ILogin): Promise<{ refreshToken: string; token: string }> => {
        return await client.post("/api/login", data)
    },
    create: async (data: ICreate): Promise<IUser> => {
        return await client.post("/api/users", data)
    },
    delete: async (id: number): Promise<void> => {
        await client.delete("/api/users/" + id)
    },
    reset: async (id: number): Promise<void> => {
        await client.post(`/api/users/${id}/reset`)
    },
    changeOwner: async (data: IChangeOwner): Promise<void> => {
        await client.post("/api/users/change_owner", data)
    }
}

export default userRepository
