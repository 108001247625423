import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router"
import middlewarePipeline from "./middlewarePipeline"

const guard =
    (useStore) =>
    (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        if (!to.meta.middleware) {
            return next()
        }

        const store = useStore()
        const middleware = to.meta.middleware as any
        const context = { to, from, next, store }

        return middleware[0]({
            ...context,
            next: middlewarePipeline(context, middleware, 1)
        })
    }

export default guard
