import { defineStore } from "pinia"
import type { IUser } from "@/interfaces"
import { ref } from "vue"

export const useStore = defineStore("store", () => {
    const user = ref<IUser>({})

    function setUser(data: IUser) {
        user.value = data
    }

    return {
        user,
        setUser
    }
})
