export const ROLE_CANDIDATE = "ROLE_CANDIDATE"
export const ROLE_RECRUITER = "ROLE_RECRUITER"
export const ROLE_ADMIN = "ROLE_ADMIN"

export const JWT_TOKEN = "jwt.token"
export const JWT_REFRESH_TOKEN = "jwt.refreshToken"

export const USER_TYPE_CANDIDATE = "candidate"
export const USER_TYPE_RECRUITER = "recruiter"
export const USER_TYPE_ADMIN = "admin"

export const SUBJECT_TYPE_LANGUAGE = "language"
export const SUBJECT_TYPE_TECHNOLOGY = "technology"
export const SUBJECT_TYPE_EXPERIENCE = "experience"
export const SUBJECT_TYPE_MAP = {
    [SUBJECT_TYPE_LANGUAGE]: "Язык",
    [SUBJECT_TYPE_TECHNOLOGY]: "Технология",
    [SUBJECT_TYPE_EXPERIENCE]: "Область работы"
}

export const REQUEST_STATUS_NEW = "new"
export const REQUEST_STATUS_DONE = "done"
export const REQUEST_STATUS_REFUSED = "refused"
export const REQUEST_STATUS_VIEWED = "viewed"
export const REQUEST_STATUS_MAP = {
    [REQUEST_STATUS_NEW]: "Новое",
    [REQUEST_STATUS_DONE]: "Выполнено",
    [REQUEST_STATUS_REFUSED]: "Отклонено",
    [REQUEST_STATUS_VIEWED]: "Просмотрено"
}

export const VACANCY_CREATE_TAB_LANGUAGES = "languages"
export const VACANCY_CREATE_TAB_TECHNOLOGIES = "technologies"
export const VACANCY_CREATE_TAB_BUSINESS_UNITS = "businessUnits"

export const VACANCY_STATUS_DRAFT = "draft"
export const VACANCY_STATUS_ACTIVE = "active"
export const VACANCY_STATUS_DISABLED = "disabled"
export const VACANCY_STATUS_OPTIONS = [
    { value: VACANCY_STATUS_DRAFT, label: "Черновик" },
    { value: VACANCY_STATUS_ACTIVE, label: "В работе" },
    { value: VACANCY_STATUS_DISABLED, label: "Выключено" }
]

export const TESTING_ROUND_STATUS_ACTIVE = "active"
export const TESTING_ROUND_STATUS_FINISHED = "finished"
export const TESTING_ROUND_STATUS_REFUSED = "refused"
export const TESTING_ROUND_STATUS_MAP = {
    [TESTING_ROUND_STATUS_ACTIVE]: "В работе",
    [TESTING_ROUND_STATUS_FINISHED]: "Завершено",
    [TESTING_ROUND_STATUS_REFUSED]: "Прервано"
}

export const TASK_TYPE_QUESTION = "Ask"
export const TASK_TYPE_TASK = "Task"
export const TASK_TYPE_DIALOG = "Dialog"
export const TASK_TYPE_MAP = {
    [TASK_TYPE_QUESTION]: "Вопрос",
    [TASK_TYPE_TASK]: "Задача",
    [TASK_TYPE_DIALOG]: "Диалог"
}

export const DELAYED_WAIT = 1 * 1000

export const QUESTIONNAIRE_STATUS_DRAFT = "draft"
export const QUESTIONNAIRE_STATUS_ACTIVE = "active"
export const QUESTIONNAIRE_STATUS_DISABLED = "disabled"
export const QUESTIONNAIRE_STATUS_MAP = {
    [QUESTIONNAIRE_STATUS_DRAFT]: "Черновик",
    [QUESTIONNAIRE_STATUS_ACTIVE]: "В работе",
    [QUESTIONNAIRE_STATUS_DISABLED]: "Выключено"
}

export const VACANCY_VARIANT_EXPERIENCE = "experience"
export const VACANCY_VARIANT_QUESTIONNAIRE = "questionnaire"
export const VACANCY_VARIANT_MAP = {
    [VACANCY_VARIANT_EXPERIENCE]: "Компетенции",
    [VACANCY_VARIANT_QUESTIONNAIRE]: "Опросник"
}
