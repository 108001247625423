<script setup lang="ts">
import tokenService from "@/services/TokenService"
import { useStore } from "@/store"
import { useRouter } from "vue-router"
import { route } from "@/router/router"

const store = useStore()
const router = useRouter()

const logout = () => {
    tokenService.removeTokens()
    router.replace({ name: route.login.name })
}
</script>

<template>
    <q-layout view="hHh lpR fFf" class="bg-grey-3 column">
        <q-header bordered class="bg-primary">
            <q-toolbar>
                <q-toolbar-title style="font-size: 190%">
                    <div class="row items-center">
                        <div class="col" style="font-size: 150%">
                            <span class="text-bold text-blue-2">HR</span>
                            <span class="text-blue-4">CRM</span>
                        </div>
                        <div class="text-body1 q-mx-md" style="line-height: 1.1">
                            <div >{{ store.user?.email }}</div >
                            <div >{{ store.user?.role.label }}</div >
                        </div>
                        <div>
                            <q-btn icon="logout" flat @click="logout" />
                        </div>
                    </div>
                </q-toolbar-title>
            </q-toolbar>
        </q-header>

        <q-page-container class="col-grow column">
            <RouterView />
        </q-page-container>
    </q-layout>
</template>

<style scoped></style>
