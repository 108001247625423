import { createRouter, createWebHistory } from "vue-router"
import LayoutView from "@/views/LayoutView.vue"
import LoginView from "@/views/LoginView.vue"
import { useStore } from "@/store"
import guard from "@/router/guard"
import requireAuth from "@/router/requireAuth"
import userRepository from "@/repositories/UserRepository"

export const route = {
    dashboard: { path: "", name: "dashboard" },
    activeSteps: { path: "active-steps", name: "activeSteps" },
    activeStep: { path: "active-steps/:id", name: "activeStep" },
    vacancies: { path: "vacancies", name: "vacancies" },
    vacancy: { path: "vacancies/:id", name: "vacancy" },
    result: { path: "results/:id", name: "vacancyResult" },
    candidates: { path: "candidates", name: "candidates" },
    candidate: { path: "candidates/:id", name: "candidate" },
    cvs: { path: "cvs", name: "cvs" },
    cv: { path: "cvs/:id", name: "cv" },
    settings: { path: "settings", name: "settings" },
    login: { path: "/login", name: "login" },
}

const auth = requireAuth(route.login.name, userRepository.get)

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            component: LayoutView,
            meta: {
                middleware: [auth]
            },
            children: [
                {
                    ...route.dashboard,
                    component: () => import("@/views/DashboardView.vue")
                },
                {
                    ...route.activeSteps,
                    component: () => import("@/views/ActiveStepsView.vue")
                },
                {
                    ...route.activeStep,
                    component: () => import("@/views/ActiveStepView.vue")
                },
                {
                    ...route.vacancies,
                    component: () => import("@/views/VacanciesView.vue")
                },
                {
                    ...route.vacancy,
                    component: () => import("@/views/VacancyView.vue")
                },
                {
                    ...route.cvs,
                    component: () => import("@/views/CvsView.vue")
                },
                {
                    ...route.cv,
                    component: () => import("@/views/CvView.vue")
                },
                {
                    ...route.settings,
                    component: () => import("@/views/SettingsView.vue")
                }
            ]
        },
        {
            ...route.login,
            component: LoginView
        }
    ]
})

router.beforeEach(guard(useStore))

export default router
