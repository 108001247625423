<script setup lang="ts">
import { onBeforeMount, ref } from "vue"
import tokenService from "@/services/TokenService"
import userRepository from "@/repositories/UserRepository"
import { useStore } from "@/store"
import { useRouter } from "vue-router"
import { route } from "@/router/router"

interface IForm {
    email?: string
    password?: string
    loading?: boolean
    invalidCredentials?: boolean
}

const form = ref<IForm>({})
const store = useStore()
const router = useRouter()

onBeforeMount(async () => {
    if (tokenService.jwt.has()) {
        await router.replace({ name: route.dashboard.name })
    }
})

const login = async () => {
    if (!form.value.email || !form.value.password || form.value.loading) {
        return
    }

    try {
        form.value.loading = true
        form.value.invalidCredentials = false
        store.setUser({})

        const data = await userRepository.login({
            email: form.value.email,
            password: form.value.password
        })

        tokenService.setTokens(data)
        await router.replace({ name: route.dashboard.name })
    } catch (e) {
        form.value.invalidCredentials = true
        tokenService.removeTokens()
    } finally {
        form.value.loading = false
    }
}
</script>

<template>
    <q-layout view="hHh lpR fFf" class="bg-grey-3 column justify-center items-center">
        <q-page-container>
            <q-card class="login">
                <q-card-section>
                    <div
                        class="column items-center q-gutter-y-md"
                        style="width: 50%; margin-left: 45%; margin-top: 20%"
                    >
                        <div class="text-h6 text-white">Вход</div>
                        <q-input
                            dense
                            filled
                            class="bg-white full-width"
                            v-model="form.email"
                            clearable
                            type="email"
                            label="Email"
                            :error="form.invalidCredentials"
                            error-message="Неверный email или пароль"
                            tabindex="1"
                            autofocus
                            :disable="form.loading"
                        />
                        <q-input
                            dense
                            filled
                            class="bg-white full-width"
                            v-model="form.password"
                            clearable
                            type="password"
                            label="Пароль"
                            tabindex="2"
                            :disable="form.loading"
                            @keyup.enter="login"
                        />
                        <q-btn
                            color="primary"
                            label="Войти"
                            :loading="form.loading"
                            @click="login"
                            class="full-width"
                            :disable="!form.email || !form.password || form.loading"
                        />
                    </div>
                </q-card-section>
            </q-card>
        </q-page-container>
    </q-layout>
</template>

<style scoped>
.login {
    background-image: url("@/assets/kandinsky-download-1707743509991 2.png");
    background-size: cover;
    min-width: 600px;
    min-height: 400px;

    .q-field__bottom {
        padding: 4px;
        text-align: center;
    }
}
</style>
